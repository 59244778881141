.modal {

	.modal-header {
		box-shadow:$modal-box-shadow;
		background-color: $row_background;
		padding-bottom: 8px;
		padding-top: 8px;
	}
	.modal-title {
		font-size: $font-size-large;
		font-weight: $weight-med;
		color: $modal-color;
		text-transform: uppercase;
		line-height: 2.3;
		text-align:left;
	}
	.close {
		font-size: $font-smaller;
		color: $blue;
	}
	.name {
		font-size: $font-smaller;
		font-weight: $weight-med;
		color: $modal-color;
		text-transform: uppercase;
	}
	.modal-data {
		font-size: $font-smaller;
		color: $modal-color;
		padding-top: 5px ;
	}
	.modal-price {
		font-weight: $weight-med;
		font-size: $font-size-large;
		color: $modal-color;
		padding: 10px 0 ;
	}
	.btn {

		font-size: $font16;
		font-weight: $weight-med;
		text-transform: uppercase;
		margin-top:20px;
		min-width: 170px;
		margin-bottom:50px;
		&:hover {
			background-color: $green-button-hover;
		}
	}
	.modal-footer {
		background-color:$row_background;
		text-align:left;
		font-size: $font-smaller;
		font-weight: $weight-med;
		color: $modal-color;
		text-transform: uppercase;
		strong {
			font-weight:$weight-med;
		}
		img {
			margin:auto;
		}
	}

	@media(max-width:$screen-xs-max){
		.modal-title {
			font-size: $font-smaller;
		}
		.modal-content {
			text-align: center;
		}


	}
}
