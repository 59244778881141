.page_header {

	.page_header_inner {
		overflow: hidden;
		margin:0 auto;
		max-width:1600px;
		position:relative;
		z-index:80;
		text-transform: uppercase;
		.map {
			height:340px;

		}

		height: 350px;
		background-size: cover;
		background-position: center bottom;
		@media(max-width:$screen-sm-max) {
			height:auto;
			min-height: auto;
		}
		@media(max-width:$screen-sm-max) {
			height:auto;
			min-height:250px;
			position:relative;
			img {
				width:100%;
			}
			h1{
				overflow:hidden;
				font-size: 20px;
				font-weight: $weight-med;
			}
			a.buttons {

				font-size: 16px;
				display: block;
				font-weight: 600;
				text-align: center;
				text-transform: uppercase;
				border:1px solid #0080FF;
				border-radius: 4px;
				margin-bottom: 21px;
				padding: 6px;

			}

			.page_header_text {
				.page_text {
					h1 {
						font-size:$font-size-h3;
					}
				}


			}


		}

		@media(max-width:$screen-sm-max) {
			.page_header_text {
				.page_text {
					// padding-top:5px !important;

					h1 {
						// margin-top: 8px;
					}

				}
			}
		}

		.page_header_text {
			position:absolute;
			top:0px;
			bottom:0;
			left:0;
			right:0;

			background-color: rgba(0,0,0,0.5);
			transform: translateY(-100%);

			.page_text {
				left: 25%;
				margin: 0 auto;
				padding-top:20px;


				h1,small {
					color:#FFF;
					letter-spacing:0px;
					// text-shadow:0px 2px 4px rgba(0,0,0,0.50);
					text-align:center;
					font-size:$font-size-h2;
					line-height: 52px;
					font-weight: 600;
					margin-bottom: 0px;
					margin-top:2px;
				}

				small {
					font-family: 'Open Sans', sans-serif;
					font-size:36px;
					display:block;
					margin-top:30px;
				}
			}

			.button {
				background-color: rgba(255,255,255,0.2);
				border:2px solid #ffffff;
				border-radius:10px;
				padding:12px 29px;
				display:inline-block;
				margin-top:34px;
				color:#FFF;
				font-size:$font-small;
				line-height: 24px;
				text-decoration: none;
				transition:color 0.5s, background-color 0.5s;
				font-weight:400;
				letter-spacing: 1px;
				text-transform: uppercase;

				max-height: 48px;

				&:hover {
					background-color:transparentize($secondary,0.2);
					color:#FFF;
				}
			}

			-moz-animation-name: dropHeader;
			-moz-animation-iteration-count: 1;
			-moz-animation-timing-function: ease-out;
			-moz-animation-duration: 1.0s;

			-webkit-animation-name: dropHeader;
			-webkit-animation-iteration-count: 1;
			-webkit-animation-timing-function: ease-out;
			-webkit-animation-duration: 1.0s;

			animation-delay:2250ms;
			animation-fill-mode: forwards;
			animation-name: dropHeader;
			animation-iteration-count: 1;
			animation-timing-function: ease-out;
			animation-duration: 1.0s;

		}

		@media(max-width:$screen-sm-max) {
			.page_header_text .page_text {
				max-width:100%;
				h1 {
					font-size:21px;
					line-height:  1.4em;
				}
				.button {
					font-size: $font-small;
				}
			}
		}
		@media(max-width:$screen-xs-max){
			.page_header_text .page_text {
				h1 {
					font-size:26px;
					line-height:42px;
				}
				.button {
					font-size: $font-smaller;

				}
			}
		}
	}

	.jump_nav {
		.fa {
			color:rgba(255,255,255,0.6);
		}

	}
}

body.index .page_text > h1 {
	font-family: 'Alegreya Sans SC', sans-serif;
	font-size:60px;
}

@-moz-keyframes dropHeader {
	0% {
		-moz-transform: translateY(-100%);
	}
	100% {
		-moz-transform: translateY(0);
	}
}
@-webkit-keyframes dropHeader {
	0% {
		-webkit-transform: translateY(-100%);
	}
	100% {
		-webkit-transform: translateY(0);
	}
}
@keyframes dropHeader {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0);
	}
}

