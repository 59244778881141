.facebook-post {
	font-size: 12px;
	font-weight: 600 !important;
	line-height: 17px;

}
.posts {
	.post-horizontal {
		padding:40px 20px;
		.title {
			margin-top:0px;
			font-size:24px;
			font-weight: normal;
			font-family: cabin;

		}

		a {
			font-size: 18px;
			font-weight: normal;

		}

		img {
			box-shadow: 0px 2px 3px 0px rgba(12,27,52,0.5);
			max-width:100%;
		}

		.fa {
			color:$red;
		}

		&:nth-child(even) {
			background-color:$primary_d;
		}
	}
}

.news {
	.post {
		font-size:16px;
		color: #434343;

		h1 {
			font-size: 30px;
			font-weight: 600;


		}
		h2 {
			font-size: 20px;

			font-weight: 600;
			text-transform: uppercase;
			margin-bottom:0;
			margin-top: 5px;
		}
		h4 {
			font-size: 22px;
			line-height: 20px;
			font-weight: 300;

		}

		p {

		}
		img {
			margin:0 auto;
			margin-top:17px;
			margin-bottom:17px;
			display:block;
			width:100%;



		}

		a {
			font-weight:normal;
			&[href^="tel"] {
				color:$red;
			}
		}

		.post-footer {
			font-size:12px;
			border-bottom:$primary_d solid 1px;
			margin-bottom:50px;
			margin-top:20px;

			.share {
				text-transform: uppercase;


			}
			.fa-facebook-square {
				font-size: 15px;
			}
			.posted_by {
				font-size: 12px;
				font-weight: 600;

				text-transform: uppercase;
				text-align: right;
				@media(max-width:$screen-xs-max){text-align:center;}
			}
			.facebook  {
				a {
					color:#0080FF;
				}
			}
			a {
				text-decoration: none;
				color:black;

			}
		}
	}

	.form {
		font-size:20px;
		margin-top:27px;

		input {
			padding-top:0px;
			padding-bottom:0px;
			height:32px;
			width:calc(100% - 89px);
		}

		.button {
			color: white;
			border-radius:10px;
			text-align:center;
			background-color:$red;
			border:none;
			padding:6px 13px;
			font-size: 18px;
			font-weight: 300;
			font-family: "Open Sans",sans-serif;
			vertical-align: middle;

			&:hover {
				text-decoration: none;
				background-color:darken($red,5);
				box-shadow: 0 0 5px rgba(0,0,0,0.7);
			}
		}
	}
}
.news-cont {
	height:0px;
}
.pagination {
	padding-left:0px;
}
.posts-backgound {
	overflow:hidden;
	background-color:transparent;
	padding-bottom: 10px;

}
.posts_jump {
	background-color:$row_background;
	padding-top:0px;

}
.posts_next {
	background-color:transparent;
	text-transform: uppercase;
	font-weight: 600;
	padding-top:30px;
	font-size:16px;

	a {
		text-decoration:none;
	}
	.fa {
		font-size: $font-size-h2;
		color:$fa-page-row;
		font-weight: $weight-light;
		margin:5px;
		&:hover {
			color:$primary !important;
		}

		&.fa-chevron-up {
			padding-right:10px;
		}

		&.fa-chevron-down {
			padding-left:10px;
		}
	}
}
.post_horiz {
	box-shadow: 0 2px 3px 0 rgba(183,183,183,0.5);
	background-color: #FFFFFF;
	margin-top: 20px;
	position:relative;
	margin-left:0;
	@media (max-width:$screen-xs-max) {
	margin-bottom:1em;
}
	.recent_news {
		a.button {
			display: inline-block;
			position:relative;
			@media (max-width:$screen-xs-max) {
				min-width:0;
				width:150px;
			}
		}
	}
	.h2 {
		font-size:$font-small;
		text-transform: uppercase;
		font-family:$headings-font-family;
		text-decoration:none;
		padding-right:15px;
		max-width: 370px;
		line-height: 1.5em;
		display:block;
		margin-top:0;
		margin-bottom:5px;
		margin-right:0;
		overflow: hidden;
		@media (max-width:$screen-xs-max) {
			font-size: $recent-news-title-font;
		}


	}
	p {
		font-size:$font-smaller;

		padding-right:15px;
		padding-bottom:0;
		margin-bottom: 0;
	}
	.button {

		margin-top: 6px;
		margin-bottom: 15px;
		text-transform: uppercase;
		font-weight:600;
		font-size: 16px;

	}
	.image{
		padding-left:0px;
		padding-right:0px;
	}
	.text {

		padding-right:0px;
		padding-top:10px;
	}
	img {
		max-width:100%;
		width: 100%;
		margin-bottom: 20px;

	}
	@media (min-width:$screen-md-min) {
		.button {
			min-width:170px;
		}

	}

	@media (max-width:$screen-xs-max) {
		min-width:100%;

		.button {
			font-size: $font-xsmall;
			width:100%;

			bottom:-20px;
		}
		img {
			max-width: 100%;
		}
		h1 {
			line-height:19px;
		}
	}

}
.recent_news {

	img {
		padding-top:0!important;
		padding-bottom:0!important;
	}
	@media (max-width:$screen-xs-max) {
		display: block;

		.text {
			position: inherit;
			display: block;
			.text-left {
				display: inline-block;
				a {
					position: relative;
					display: inline-block;
					z-index:89;

				}
				a.button {margin-bottom: 32px;}
			}

		}
	}
}
