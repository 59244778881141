.pack_designs {

	width:100%;
	margin-left:-10px;
	margin-bottom: 15px;
	@media(min-width:$screen-xs-max){
		& {
			width:150%;
			margin-left:-25%;
		}
	}
	.image-link {
		box-shadow: 0 2px 3px 0 rgba(183,183,183,0.5);

		.image-link-inner > div {
		}
		padding-bottom: 15px;
		margin-bottom: 25px;

		.button {
			margin-top:20px;
		}

		h5,p {
			margin:0px;
		}
	}

	&:after {
		clear:both;
	}
}
