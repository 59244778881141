.copyright {
		background-color: $dark-blue;
		color: white;
		position:absolute;
		left:0px;
		height:67px;
		width:100%;
		a {
			color:white;
		}
	}
