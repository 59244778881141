.social {
		display: inline;
    	padding-left: 10px;

		li {
			list-style:none;
			display:inline-block;
			font-size: 18px;

			&.facebook .fa {
				color:#0079B8;
			}

			&.twitter .fa {
				color:#00AAF1;
			}

			&.google .fa {
				color:#E14932;
			}

			&.youtube .fa {
				color:#C82D24;
			}
		}
	}

