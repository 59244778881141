.product {
	font-size: $font-smaller;
	box-shadow: $product-shadow;
	margin:1em;
	position:relative;
	padding-right:15px;
	min-height:165px;
	@media(max-width:$screen-sm-max){
		min-height:150px;

	}
	.image_holder {
		overflow: hidden;
		position:relative;
	}
	p {
		margin: 0;
	}
	.label {
		-webkit-backface-visibility: hidden;
		position:absolute;
		top:0;
		left:0;
		z-index:1;
		transform-origin: top left;
		text-align:center;
		font-weight: 500;
		font-size: $font-smaller;
		line-height: 15px;
		text-transform: uppercase;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		width: 140px;
		height: 67px;
		padding-top: 30px;
		transform: translate(-50px, 50px) rotate(-45deg);
	}
	.text {
		display: block;
	}
	a {
		color: $modal-color;
		text-decoration:none;
	}

	@media(max-width:$screen-xs-max){
		display: block;
		min-height: 0;

		padding-left:15px;
		.image_holder {
			max-height: 100%;
			img {
				width:100%;
			}
		}
		.text {
			text-align: center;
			min-height:0;
			position:relative;
			margin:1em;
		}

		.product_footer {

			text-align:center;
			.price_from_text{
				text-align:center;
			}

		}
		.title {
			margin:1em;
		}
		.contents, .price_from_text {
			margin: 1em;
		}


	}

	.title {
		font-size: $font16;
		font-weight: $weight-med;

		text-transform: uppercase;
		margin-bottom: 5px;
	}



	strong {
		font-weight: $weight-med;
	}

	.description {
		font-size: $font-smaller;
		display:inline-block
	}



	.price_from_text {
		font-weight: $weight-light;
		font-size: $font-smaller;
		text-align:right;


	}

	.product_footer {
		margin:1em 0 1em;


		@media(max-width:$screen-sm-max){
			bottom:0;


		}
		@media(max-width:$screen-xs-max){
			position:relative;

			margin:0;

		}


		.contents {

		}
		.reduced {
			color:#FF0000;
			font-size: $font16;
			text-decoration: line-through;
			margin-right:1em;

		}

		.price {
			color:$primary;
			font-weight: $weight-med;
			font-size: $font-small;
			margin:1em;
			text-align:right;
			@media(max-width:$screen-xs-max){ text-align:center;}

		}

		.product_details {
			text-align: right;

			@media(max-width:$screen-xs-max){ text-align:center;}
		}
	}
}
.product_details {
	h1,h2,h3  {
		font-size: $font-size-large;
		font-weight: $weight-med;

		text-transform: uppercase;
		@media(max-width:$screen-xs-max){
			font-size:$font16;
			text-align:center;
		}
	}
	.h1 {
		margin-bottom: 4px;
	}
	.image_holder {
		margin-top:28px;
		overflow: hidden;
		width: 100%;
		padding-bottom: 60%; // height = 0.7 * width
		position:relative;
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
		img {
			transition:transform 0.3s ease-out;
			width:100%;
			position:absolute;
			top:0;
			left:0;
			@media(max-width:$screen-xs-max){
				height:100%;

			}
		}
		margin-bottom: 5px;

		&.main_image {
			position:relative;
			img {
				width:auto;
			}
			&:after {
				content:"\f00e";
				font-family: FontAwesome;
				pointer-events: none;
				position:absolute;
				color: $primary;
				font-size: 34px;
				bottom:17px;
				right:8px;
			}
		}
	}
	.label {
		-webkit-backface-visibility: hidden;
		position:absolute;
		top:0;
		left:0;
		z-index:1;
		transform-origin: top left;
		text-align:center;
		font-weight: 500;
		font-size: $font-smaller;
		line-height: 15px;
		text-transform: uppercase;
		width: 140px;
		height: 67px;
		padding-top: 30px;
		transform: translate(-50px, 50px) rotate(-45deg);
	}

	.image_viewer {
		position:relative;

	}

	.product_product_image {
		margin-left:-5px;
		margin-right:-5px;

		div {
			display:block;
			float:left;
			width:33.33%;
			padding-left:5px;
			padding-right:5px;
			vertical-align: baseline;
			margin-top: 5px;
			margin-bottom: -2px;
			overflow: hidden;
			text-align:center;
			max-height: 100px;

			img {
				border: 3px solid $link;
				max-width:100%;
				max-height: 100px;
				position:relative;

			}

			&.active {
				margin-bottom:-2px;

				img {
					border: 3px solid $dark-blue;
				}
			}


		}

		&:after {
			content: '';
			display: block;
			clear: both;
		}
	}


	.small_image {
		max-width:149px;
		height:105px;
		display:inline-block;
		margin-right:0;
		margin-bottom: 16px;

	}
	.page_content {
		p {
			margin-bottom:20px;
		}

		ul {
			margin-top: 20px;


			li {

				list-style: none;
				vertical-align: middle;
				font-weight: $weight-med;
				font-size: $font-small;
				text-align: left;



				&:before {

					font-weight: normal;
					content: "\f05a";
					font-family: FontAwesome;
					color: #66B3FF;
					font-size: $font-size-h1;
					margin-left: -45px;
					margin-right: 20px;
					display: inline-block;
					vertical-align: middle;

				}
			}
		}
		@media(max-width:$screen-xs-max){
			text-align: center;
			ul {
				li {
					font-size: $font-smaller;
				}
			}
		}
	}

	.product_options {
		padding-left: 15px;
		padding-top:15px;
		padding-bottom:30px;
		background-color: #F7F7F7;
		@media(max-width:$screen-xs-max){

			padding: 15px;
		}
		select {
			font-size: $font16;

			color: $shop-select;
			margin-top: 11px;
			margin-bottom:10px;

		}
		.cart_quantity {
			margin-top: 10px;
			margin-bottom:10px;
			padding-right:2px;
			@media(max-width:$screen-xs-max){
				text-align: center;
				font-size: 24px;

			}
		}
		.table_selector {
			position:absolute;
			border: $shop-table-selector;
			top:29px;
			width:95%;
			height: 31px;
			transition: top 0.7s ease-out;
			@media(max-width:$screen-sm-max){
				width:100%;
				top:49px;
			}
			@media(max-width:$screen-xs-max){
				top:31px;
				width:96%;
			}

			&.volume2 {
				top:58px;

				@media(max-width:$screen-sm-max){
					top:77px;
				}
				@media(max-width:$screen-xs-max){
					top: 59px;
				}
			}
			&.volume3 {
				top:86px;
				@media(max-width:$screen-sm-max){
					top:106px;
				}
				@media(max-width:$screen-xs-max){
					top: 89px;
				}
			}
			&.volume4 {
				top:119px;
				@media(max-width:$screen-sm-max){
					top:137px;
					height: 47px;
				}
				@media(max-width:$screen-xs-max){
					top: 122px;
					height:31px;
				}
			}

		}
		.discount_table {
			position:relative;
			margin-top:11px;
			max-height: 161px;
			width:100%;
			font-size: $font-smaller;

			line-height: 19px;
			padding-right: 15px;
			@media(max-width:$screen-sm-max){
				padding-right: 0px;
			}
			@media(max-width:$screen-xs-max){
				padding-right: 15px;
			}
			table {
				width:100%;
			}
			thead {
				background-color: $modal-color;
				color: $row_background3;
				font-weight: $weight-med;
			}
			tbody {
				background-color: $row_background3;
			}
			tr:nth-child(even){
				background-color: $row_background2;
			}


			td {
				padding-top:6px;
				padding-left:10px;
				padding-bottom:5px;
			}
		}
	}
	.product_information {
		font-size: $font-small;

		@media(max-width:$screen-xs-max){
			p {
				line-height: 1.4em;
			}
		}
	}
	.pack_price_label {
		text-transform: uppercase;
		font-size: $font-small;

		@media(max-width:$screen-xs-max){
			text-align: center;
		}

	}
	.contains, .product_code {

	}
	.contains {
		margin-bottom: 10px;
	}

	.product_price {
		@media(max-width:$screen-xs-max){
			text-align: center;
		}
		.vat {
			font-size: $font-smaller;

		}

		.cart_value {

			font-size: $font-size-xlarge;


		}

		.btn {
			font-size: $font16;
			font-weight: $weight-med;
			text-transform: uppercase;
			margin-top:20px;
			@media(max-width:$screen-xs-max){
				margin-bottom: 15px;
			}

			&:hover {
				background-color: $green-button-hover;
			}
		}
	}

}
@media(max-width:$screen-xs-max){
	.product .description {
		font-size: $font-smaller;
	}
	.product_code, .contains, .product_information p, .page_content p {
		font-size:$font-smaller;
	}
}
