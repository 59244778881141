#home_nav {
	top:131px;
	position:fixed;
	width:100%;
	z-index: 90;

}
#other_nav {
	// top:80px;
	position:relative;
	width:100%;
	z-index: 90;
}

.toggle_nav {
	margin:0.3em;
}
#nav {
	background-color:$blue;
	@media (max-width:$screen-sm-max) {
		background-color:transparent;
		}

	.nav_menu_bg {
		background-color:$primary;
		width:70%;
		right: 0%;
		left:0%;
	}
	.logo {
		position:relative;
		margin-top:3%;
	}

	.mobile_menu {
		position:relative;
		top:auto;
		bottom:auto;
		.fa-2x {
			font-size:1em;
		}
	}

	hr {
		margin-top:8px;
		margin-bottom:8px;
	}

	@media(max-width:$screen-sm-max) {
		.mobile_menu {
			padding:15px;
		}
	}

	ul {
		list-style-type:none;
		margin:0;

		padding:0;
		font-family:"Open Sans",Sans;
		font-size:14px;
		color:#ffffff;
		letter-spacing:0px;


		& > li {
			display:inline-block;
			text-align:center;

			&.active > a {
				border:$dark-blue solid 1px;
				text-decoration: none;
				background-color:$dark-blue;
			}

			ul {
				/*display:none;*/
				position:absolute;
				background-color:#ECECEC;
				z-index: 100;

				// padding:10px;

				border-top: transparent none 1px;

				&.active {
					display:block;
				}

				li {
					display:block;
					text-align: left;

					 a {
						color:$primary;
						padding-bottom:10px;
						padding-top: 11px;
						padding-left:22px;
						padding-right:46px;

						&:hover, &:active,&:focus {
							background-color:$primary-dropdown;
							color:#FFF;
							border-color:transparent;
						}
					}

					&.active a {
						background-color:$primary-dropdown;
						color:#FFF;
						border-color:transparent;
					}
				}
			}
		}
		a {
			padding:10px;
			display: block;
			color:white;
			text-transform: uppercase;
			transition:background-color 0.2s ease-out;
			border:transparent solid 1px;

			&:hover, &:active,&:focus, &.active {
				text-decoration: none;
				background-color:$dark-blue;
				border:$dark-blue solid 1px;
			}

			@media(min-width:$screen-sm-max) {
				& {
					padding:10px;
				}
			}
		}
	}

	.phone a {
		font-family:"Open Sans",Sans;
		font-size:$font-size-large;
		color:#ffffff;
		letter-spacing:0px;
		text-align:right;
		padding-right:20px;

		.fa {
			color:$fa-phone-color;
		}
	}

	.toggle_nav {
		font-size:1.3em;
		color:#FFF;
		border:solid 1px #FFF;
		margin-top:15px;

		&.active {
			background-color:$dark-blue;
		}
	}

	@media(max-width:$screen-sm-max) {
		& {
			position:fixed;
			// width:100%;
			min-height: 0px;

		}

		.mobile_menu {
			transform:translate3d(100%, 0px, 0px);
			transition: transform 1s;
			position:fixed;
			top:80px;
			bottom:inherit;
			height:5000px;
			width:100%;
			background-color:transparentize($mobile-blue,0.1);
			&.banner-active {
				top:130px;

			}

			&.active {
				transform:translate3d(0px, 0px, 0px);

			}

			a {
				font-size:1.4em;
				font-weight: 500;
			}

			ul {
				& > li {
					display:block;
					text-align:left;

					& > ul.dropdown {
						display:block;
    					position: relative;
    					background-color:transparentize(#000,0.7);
    					margin-left:-15px;
    					margin-right:-15px;
    					padding-left:15px;
    					padding-right:15px;
    					border:transparent solid 1px;

    					a {
    						font-size: 1.1em;
    						background-color:transparent;
    						color:#FFF;
    					}

    					& > li:hover > a, & > li:active > a, & > li.active > a {
							background-color:$primary !important;

						}
					}

					&:hover > a, &:active > a, &.active  > a {
						border:transparent solid 1px;
						// color:$blue;
						background-color: $primary;
					}
				}
			}

			.phone {
				text-align: left;
				padding-left:15px;

			}
		}
	}
}

body.no_scroll {
  overflow: hidden;
}
