.party_pack {
	& > .item {
		padding-left:50px;
		padding-right:50px;

		h2 {
			margin-top:0px;
			line-height: 33px;
			font-size: 24px;
			text-transform: uppercase;
		}

		img {
			margin-top:5px;
		}

		ul {
			margin-left:20px !important;
		}
	}
}

.carousel-control {
	background-image:none;
	background-color:transparent;

	&.right {
		text-align:right;
		display: block;
		opacity: 1;
		background-image:none;
	}

	&.left {
		text-align:left;
		display: block;
		opacity: 1;
		background-image:none;
	}

	&:hover,&:active,&:focus {
		background-image:none;

	}

	.fa {
		position:relative;
		font-size:48px;
		margin-top:150px;
		color:rgba(172,172,172,0.8);
		text-shadow:none;
		&:hover {
			color:darken(rgba(172,172,172,1),10%);
		}
	}
}

