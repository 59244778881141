.product-listing {
	label {
		text-transform: uppercase;
	}
	@media(max-width:$screen-xs-max){
		label {
			font-size:16px;
		}
		.form-inline {
			margin:0 1em 1em 0;
		}
	}
}
