.product_list_page_categories {
	padding-left:0;
	font-size:$product-categories-list-font-size;

	li {
		list-style: none;
		margin:2px 0;
		font-weight: normal;
		&:first-child {
			margin-top:0px;
		}

		a {
			text-decoration: none;
			background-color:$product-categories-list-background;
			padding:5px 10px;
			display:inline-block;
			text-transform:uppercase;
			width: 100%;
			&:hover {
				background-color:$primary-dropdown;
				color:#FFF;
			}
		}

		&.active a {
			background-color:$product-categories-list-background-active;
			color:#FFF;
		}
	}
	@media(max-width:$screen-xs-max){
		text-align: center;
	}
}
