.jump_nav {
	margin-top:1em;
	.fa {
		font-size: $font-size-h2;
		color:$fa-page-row;
		font-weight: $weight-light;
		margin:5px;
		&:hover {
			color:$primary !important;
		}

		&.fa-chevron-up {
			padding-right:10px;
		}

		&.fa-chevron-down {
			padding-left:10px;
		}
	}
}
