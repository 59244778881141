.contact_form {
	margin-top:24px;
	.small-contact {
		textarea.form-control {
			height: 170px;
		}
	}
	.full-contact {
		textarea.form-control {
			height: 143px;
		}
	}
	.form-control {
		font-size: $font-smaller;

	}
	h2 {
		margin-top: 10px;
	}
	.file_upload {
		width:100%;
		border-style:none;
		background-color: transparent;
		box-shadow:none;

	}
	label {
		font-family: "Open Sans";
		font-weight: 400;
		font-size:$font-small;
	}

	input:not([type^="checkbox"]) {
		margin-bottom: 15px;
	}
	input.form-control {
		height: 34px;
	}
	.form-group {
		margin-bottom:20px !important;
	}
	.btn {
		font-size:$font-small;
		font-family: $headings-font-family;
		font-weight: $weight-med;
		text-transform: uppercase;


	}
	.green {
		background: $green-button;
	}
	.title {
		font-size: $font-small;
		text-transform: uppercase;

	}
	.title-sub-text {
		font-size: $font-xsmall !important;

	}
	.property_checks {
		padding-left:0px;
		list-style: none;
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none;   /* Chrome/Safari/Opera */
		-khtml-user-select: none;    /* Konqueror */
		-moz-user-select: none;      /* Firefox */
		-ms-user-select: none;       /* Internet Explorer/Edge */
		user-select: none;

		li {

			label {
				font-weight: $weight-light;
				padding: 2px 20px;
				padding-left:0px;
			}
		}
	}
}
