.shop_content.pages_rows {
	.page_row {
		background-color:$link;
		font-size:$font-smaller;
		h1 {
			font-size: $font16;
			font-weight: 600;

			text-transform: uppercase;
		}
		.button_wrap {
			a {
				text-align: center;
			}
		}


	}
}
.pages_rows .page_row.bullet_star ul {
	margin-left: 1px!important;

	li {
		font-size: 13px;

	}

}
.track {
	button {
		min-width: 170px;

            font-weight: 600;
            font-size: 16px;

            margin-top: 25px;

            text-transform: uppercase;
		&:hover {
			background-color: $secondary;
			color: #FFF;
		}
	}
}
