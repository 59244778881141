body.edit_active {
	.editable {
		position:relative;

		.edit-inline {
			position:absolute;
			right:10px;
			top:10px;
			display:none;
			font-size: 18px;

			.btn-editor {
				color: white;
				border-radius:10px;
				text-align:center;
				background-color:$edit-color;
				border:none;
				padding:6px 13px;
				cursor:pointer;

				.fa {
					color:#fff;
				}

				&:hover {
					text-decoration: none;
					background-color:darken($edit-color,5);
					box-shadow: 0 0 5px rgba(0,0,0,0.7);
				}
			}
		}

		&:hover {
			outline: 2px dashed $edit-color;
			z-index: 9999;

			& > .edit-inline {
				display:block;
			}
		}
	}
}
