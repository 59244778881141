.page {

	.page_row {
		padding-top:15px;
		padding-bottom:15px;
		text-align:center;
		font-size:18px;



		ul {
			text-align: left;
			list-style-type: none;
			display: inline-block;

			li {
				font-size: 24px;
				font-weight: normal;

				&:before {
					font-family: FontAwesome;
					content: "\f058";
					font-size: 1.8em;
					margin-right: 15px;
					display: inline-block;
					color:$primary_d;
					vertical-align: middle;
				}
			}
		}

		&:first-child {
			font-size:24px;
		}

		&:nth-child(even) {
			background-color:$primary_l;
		}

		.button {
			font-weight:500;
			font-size: 20px;
			color: white;
			width:200px;
			border-radius:10px;
			text-align:center;
			background-color:$red;
			margin:20px 0px;
			padding:0px 12px;
			&:hover {
				text-decoration: none;
				background-color:darken($red,5);
				box-shadow: 0 0 5px rgba(0,0,0,0.7);
			}
		}

	}
}
