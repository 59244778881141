.shop {
	color: $modal-color;

	h2 {
		font-size: $font-size-h2;
		font-weight: $weight-med;
	}
	p {
		font-size: $font-small;
		font-weight: normal;

	}
	label {
		font-weight: $weight-med;

	}
	.item-th {
		padding-right: 60px;
	}
	.item-price-th {
		padding-right: 114px;
	}
	.text-danger {
		font-size: $font-smaller;
		color: $danger-red;
	}
	.cart-basket-list {
		margin-top: 20px;
	}
	.border td {
		border-top: solid 1px $secondary;
	}
	.cart-basket-list th {
		text-transform: uppercase;
		font-size: $font16;
		border-bottom: solid 1px $secondary;

	}
	th.detail {
		font-size: $font-smaller;
		font-weight: $weight-light;
	}
	.product-details {
		font-size: $font-smaller;
		.detail {
			margin-top:5px;
		}
	}
	.delivery-options {
		font-size: $font16;
		.title {
			margin-bottom:15px;
		}
		input[disabled] + span  {
			color:$delivery-options;
		}
		input[type=checkbox] + span  {
			color:$modal-color;
		}
		label {
			color:$modal-color;
		}

	}
	.align-right {
		text-align: right;
	}
	.note {
		font-size:$font-smaller;
		margin-bottom: 24px;
		width:70%;
	}
	.total-price {
		text-align: right;
		font-size: $font-size-large;
		text-transform: uppercase;
		font-weight: $weight-light;
		padding-right:15px;
	}
	.totals {
		font-size: $font16;
		font-weight: $weight-med;
		td {
			padding: 20px 0 25px;
		}
	}
	.value {
		font-weight: $weight-med;
		font-size: $font-size-large;
	}
	tfoot tr td {
		padding: 20px 0 25px;
	}
	.form-control {
		padding-right: 0;

	}
	.table {
		margin-bottom:0;
	}
}
.row-background {
	background-color:$row_background;
		@media (max-width:$screen-sm-max) {
			background-color:transparent;
		}
}

@media(max-width:$screen-xs-max){
	.shop {
		thead {
			border-top: 0;
			border-bottom: solid 1px $secondary;
			span {
			display: none;

			}
		}
		.cart-basket-list th {
			border-bottom: 0;
		}

		h2 {
			font-size: $font-size-large;
		}
		p {
			font-size: $font-small;
		}
		.mobile {
			width: calc(100vw-50px);
			overflow-x: scroll;
		}
		.totals {
			font-weight: $weight-light;
		}
		tr,td:nth-of-type(2) {
			display: block;
			width: calc(100vw - 30px);

		}
		.delivery-price {
			td:nth-of-type(1) {
				width: 60vw;
				font-weight: 700;
				display: table-cell;
				padding: 20px 0 25px;
			}
			td:nth-of-type(2) {
				width: 40vw;

				display:table-cell;
				padding: 20px 0 25px;
			}
		}
		.price-ex-vat, .price-vat {
			td:nth-of-type(1) {
				width: 70vw;
				font-weight: 700;
				display: table-cell;
			}
			td:nth-of-type(2) {
				width: 30vw;

				display:table-cell;
			}

		}
		.cart-top {
			input[type=text]{
				text-align: center;
				font-size: $font-medium;
				width: 30vw;
			}
			font-size: $font16;
			tr,td:nth-of-type(3) {
				width: 30vw;
				border-top: none;
			}
			tr,td:nth-of-type(4) {
				width: 30vw;
				border-top: none;
				vertical-align: top;
			}
			tr,td:nth-of-type(5) {

				border-top: none;
			}
			tr,td:nth-of-type(6) {
				display: block;
				border-top: none;
			}
			tr td:last-of-type {
				display: block;
				border-top: none;
			}
		}


		.product-details {
			font-size: $font16;
		}
		.mobile-price {
			display: block!important;
			font-size: $font-medium;

		}
		.note {
			text-align: center;
			display: block;
			width:100%;
		}
		.total-price td{


		}
		tfoot td:nth-of-type(1) {
			width: 80vw;
			font-size:19px;
			display: table-cell;
		}
		tfoot td:nth-of-type(2) {
			width: 20vw;
			font-size:20px;
			font-weight: 700;
			display:table-cell;
		}
		tfoot tr td:last-of-type {
			padding:0;
		}
		table {
			margin-bottom:0;
		}
		tfoot .total-price {

			font-size:16px;

		}
		.delivery-options {
			font-size: 15px;
			text-align:left;
			display: block;
			width:100%;
		}
		.del-cost {
			font-size: $font16;
		}
	}
}
.continue_shopping_wrap {
	text-align: left;
	padding-top:5px;
	a {
		color:$primary;
		font-size: $font-smaller;
		text-transform: lowercase;
	}
	.fa {
		// font-size:  $font-small;
	}
	@media (min-width:$screen-sm-min) {
		text-align: right;

	}
}
.details_form {
	.btn {
		&:hover {
			background-color: $green-button-hover;
		}
	}
}
