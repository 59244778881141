.filter_bar {

    padding: 10px;
    margin-bottom: 20px;
    .form-control {
        font-size: 16px;
        line-height: 22px;
        width:74%;
        height: 32px;
        @media(max-width:$screen-xs-max){
        width:98%;
        }


    }
    label {

        font-weight: normal;
        text-transform: uppercase;
        padding-right: 5px;
        font-size: 18px;
        line-height: 24px;
        @media(max-width:$screen-xs-max){
       display: none;
        }
    }
}
