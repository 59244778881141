.post_category_links {
	list-style-type:none;
	padding-left:0px;
	text-align:right;
	font-size:18px;
	text-transform:uppercase;

	li {
		margin-top:6px;
		margin-bottom:6px;
	}

	li.active a {
		color:$primary_d;
	}
}
