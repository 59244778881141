#footer {
    background: $footer_background;
    padding-top: 29px;
    color: $primary;

    font-weight: $weight-light;
    line-height: 30px;

    h5 {
        font-size: 22px;
        font-weight: $weight-med;
        line-height: 30px;
        color: #000;
    }

    p {
        font-size: 16px;
    }

    .address {
        line-height: 22px;
        color: #000;
        font-weight: 400;
    }

    .fa {
        font-weight: 400;
        font-size: 15px;
        margin-right: 10px;
    }

    .fa-facebook-square {
        color: $dark-blue;
    }

    .fa-instagram {
        color: black;
    }

    .fa-linkedin-square {
        color: $blue;
    }

    .fa-youtube-square,
    .fa-phone-square,
    .fa-map-marker {
        color: red;
    }

    a {
        color: $primary;
        text-decoration: none;
        font-size: 16px;
        font-weight: normal;

    }

    .nav {
        padding-left: 0;

        a,li {
            line-height: 30px;
        }

        li {
            list-style: none;

            ul.dropdown {
                display: block !important;
                padding-left: 1em;
                margin-top: 0;
            }
        }
    }
}
