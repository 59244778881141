.property-links {

	.col-sm-12 {
		min-height:0px;
	}

	ul {
		padding-left:0;
		text-align: center;
		li {
			display:inline-block;

			a {
				display:block;
				padding:5px 0px;
				margin-right:15px;
				margin-left:15px;
				color:$primary;
				font-weight: normal;
				text-decoration: none;
				border-bottom: 2px solid transparent;
				text-transform: uppercase;
				font-size: 16px;

				&:hover {
					color:$secondary;
					border-bottom: 2px solid darken($secondary,5%);
					text-decoration: none;
				}
			}

			&.active a {
				color:$secondary;
				border-bottom: 2px solid darken($secondary,5%);
			}
		}
	}
}
