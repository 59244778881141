.pages_rows {

    .page_row:nth-child(odd) {
        background-color: $row_background;
    }

    &.alternate {
        & > .page_row:nth-child(odd) {
            background-color: #FFF;
        }

        .page_row:nth-child(even) {
            background-color: $row_background;
        }
    }

    .page_row {
        padding-bottom: 30px;
        font-size: $body-font;


        iframe {
            width: 100%;
        }

        img {
            max-width: 100%;
            // padding-bottom: 15px;
            // padding-top: 15px;
        }

        ul {
            text-align: left;
        }

        img.primary_image {
            margin: 5px;
            width: 100%;
        }

        .with_image > .title-sub-text {
            margin-top:12px;
            text-align:left;
        }

        .title-sub-text {
            font-size: 20px;
            font-weight: 300;

        }

        h3 {
            margin-top: 5px;
        }

        table {
            font-size: 12px;
            width: 100%;

            @media(min-width: $screen-xs-max){
                & {
                    font-size: 14px;
                }
            }

            @media(min-width: $screen-sm-max){
                & {
                    font-size: 16px;
                }
            }
        }

        .title.h2 {
            text-decoration: none;
            transition: color 0.5s;

            &:hover {
                color: $secondary;
            }
        }

        .bordered {
            border: $primary solid 1px;
        }

        .button {
            min-width: 170px;
            font-weight: 600;
            font-size: 16px;
            margin-top: 25px;
            text-transform: uppercase;
            &.large {
                font-size: 20px;
            }

            &:hover {
                background-color: $secondary;
                color: #FFF;
            }
        }

        .button_centered .button_wrap {
            text-align: center;
        }





@import "resources/assets/sass/_partials/bullets";

        .page_row_inner {

            .title-sub-text {
                margin-bottom:20px;
            }
            h3 {
                font-size: 24px;
                font-weight: 600;

                text-transform: uppercase;
            }

            img {
                padding-bottom: 15px;
                padding-top: 15px;
            }

            & > ul {
                margin-left: 22%;
                margin-right: 21%;
                @media(max-width: $screen-xs-max){
                    margin-right: 5px;
                    margin-left: 30px;
                }

            }
        }
    }
}


@media(max-width: $screen-xs-max){
    .pages_rows {
        .page_row {
            text-align: center;
            padding-bottom: 20px;
            font-size:$mobile-body-size;

            h2 {
                font-size: $mobile-title-size;
            }

            .button {
                display: block;
                text-align: center;
                margin: 1em auto 0;
                width: 100%;
                &.large {
                    width: 80%;
                }
            }
        }
    }
}
