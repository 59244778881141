&.bullet_circle_tick {

    ul {
        margin-top: 20px;
        margin-left: 80px;

        li {
            list-style: none;
            vertical-align: middle;
            font-weight: 600;
            font-size: 18px;
            text-align: left;
            margin-bottom: 20px;
            line-height: 24px;

            &:before {
                position: absolute;
                font-weight: normal;
                content: "\f05d";
                font-family: FontAwesome;
                color: #7ACF1A;
                font-size: 40px;
                margin-left: -45px;
                margin-right: 20px;
                display: inline-block;
                vertical-align: middle;
                line-height: 40px;
                margin-top: -5px;
            }
        }
    }
}
&.bullet_star {

    ul {
        margin-top: 20px;
        margin-left: 80px;

        li {
            list-style: none;
            vertical-align: middle;
            font-weight: 600;
            font-size: 18px;
            text-align: left;
            margin-bottom: 20px;
            line-height: 24px;

            &:before {
                position: absolute;
                font-weight: normal;
                content: "\f006";
                font-family: FontAwesome;
                color: #FF8000;
                font-size: 40px;
                margin-left: -45px;
                margin-right: 20px;
                display: inline-block;
                vertical-align: middle;
                line-height: 40px;
            }
        }
    }
}
&.bullet_sun {

    ul {
        margin-top: 20px;
        margin-left: 80px;

        li {
            list-style: none;
            vertical-align: middle;
            font-weight: 600;
            font-size: 18px;
            text-align: left;
            margin-bottom: 20px;
            line-height: 24px;

            &:before {
                position: absolute;
                font-weight: normal;
                content: "\f185";
                font-family: FontAwesome;
                color: #ffb74d;
                font-size: 40px;
                margin-left: -45px;
                margin-right: 20px;
                display: inline-block;
                vertical-align: middle;
                line-height: 40px;
                margin-top: -5px;
            }
        }
    }
}

&.bullet_tree {

    ul {
        margin-top: 20px;
        margin-left: 80px;

        li {
            list-style: none;
            vertical-align: middle;
            font-weight: 600;
            font-size: 18px;
            text-align: left;
            margin-bottom: 20px;
            line-height: 24px;

            &:before {
                position: absolute;
                font-weight: normal;
                content: "\f1bb ";
                font-family: FontAwesome;
                color: #7ACF1A;
                font-size: 40px;
                margin-left: -45px;
                margin-right: 20px;
                display: inline-block;
                vertical-align: middle;
                line-height: 40px;
                margin-top: -5px;
            }
        }
    }
}
&.bullet_snowflake {

    ul {
        margin-top: 20px;
        margin-left: 80px;

        li {
            list-style: none;
            vertical-align: middle;
            font-weight: 600;
            font-size: 18px;
            text-align: left;
            margin-bottom: 20px;
            line-height: 24px;

            &:before {
                position: absolute;
                font-weight: normal;
                content: "\f2dc";
                font-family: FontAwesome;
                color: #0080FF;
                font-size: 40px;
                margin-left: -45px;
                margin-right: 20px;
                display: inline-block;
                vertical-align: middle;
                line-height: 40px;
                margin-top: -5px;
            }
        }
    }
}
