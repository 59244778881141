#home_header {
	position:fixed;
	height: 135px;
	width:100%;
	z-index: 90;
	background: $header_background;
	img {
		margin: 0 auto;
	}
	@media(max-width:$screen-sm-max) {
		height: 80px;
		img {
			max-width:80%;
		}
	}

	.logo {
		margin-top:2px;
		text-align: center;
		z-index:9;
		@media(max-width:$screen-sm-max) {
			margin-left: 1.2em;
		}
	}

	.slogan {
		font-family:$headings-font-family;
		font-weight:$weight-light;
		line-height: 33px;
		max-width:340px;
		font-size:$font-size-large;

		@media(max-width:$screen-md-max) {
			font-size:$header_mobile_md;
			margin-top:25px;
		}
	}
	.phone {
		font-size:$font-size-large;
		@media(max-width:$screen-md-max) {
			& {
				font-size:$header_mobile;
			}

		}
		@media(max-width:$screen-sm-max) {
			& {
				font-size:$header_mobile_sm;

			}
			a {
				font-size: $header_mobile_sm;
			}

		}
	}
	.red {
		color:#FF0000;
		font-weight: $weight-med;
	}
	ul {
		list-style-type: none;
		font-size: $font-small;
		float:right;
	}
	li:before {
		color: $primary;
		font-family: 'FontAwesome';
		content: '\f105';
		font-size:$font-size-large;
		line-height: 24px;
	}
	a {
		color:$text-color;
		cursor:pointer;

	}
	h3 {
		margin-top:10px;
	}
	.btn {
		font-size: 30px;
		z-index:10;
	}




}
.banner-wrap {
	position:fixed;
	width:100%;
	z-index: 90;
}
.banner-menu {
	position:fixed;
	width:100%;
	z-index: 90;
	transition: all .5s ease-in;
	transform:translateY(-50px);
	&.actives {
		width:100%;
		transform:translateY(0);
	}
}
.banner {
	height:50px;
	top:-50px;
	background-color:$modal-color;
	color:$row_background3;
	font-size:$font-size-large;
	font-weight:$weight-med;
	text-align:center;
	text-transform:uppercase;
	line-height:50px;


	@media(max-width:$screen-xs-max) {
		font-size:$font-small;
	}
	span {
		color: #66B3FF;
		font-size:$font-size-large;
		font-weight:$weight-med;
		line-height:50px;
		@media(max-width:$screen-xs-max) {
			font-size:$font-small;
		}
	}


}
#header {
	height: 80px;
	width:100%;
	z-index: 90;
	background: $header_background;
	display:block;
	.logo {
		margin-top:2px;
	}
	.slogan {
		font-family:$headings-font-family;
		font-weight:$weight-light;
		max-width:340px;
	}
	.phone {
		font-size:$font-size-large;

		@media(max-width:$screen-sm-max) {
			& {
				font-size:$header_mobile_md;
			}


		}
	}
	.red {
		color:#FF0000;
		font-weight: $weight-med;
	}
	ul {
		list-style-type: none;
	}
	li:before {
		color: $primary;
		font-family: 'FontAwesome';
		content: '\f054';
	}
	a {
		color:$text-color;
		cursor:pointer;
	}
	.btn {
		font-size: 30px;
		z-index:10;
	}
}
#breadcrumbs {
	background-color: $row_background;
}
#breadcrumb-inner {

	left:215px;
	width:100%;
	li {
		font-size: $font-smaller;
		line-height: 19px;
		text-transform: uppercase;
		list-style: none;
		display:inline-block;
	}
	.breadcrumb> li + li:before {
		content: ">";
		padding: 0 5px;
	}
	ol {
		padding:7px 0;
		margin-bottom:0;
	}
}
