.brc-certificate {
	padding: 15px;
	background-color: $brc_background;
	p {
		font-size: 14px;
		margin-top: 10px;
	}

	@media(max-width:$screen-xs-max){
		.img-holder {
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				max-height:150px;
				margin-bottom: 15px;
			}
		}
		p {
			text-align: center;
			margin-top: 0;
		}
	}
}
