$primary:#0080FF;
$primary-dropdown: #66B3FF;
$primary_l:lighten($primary,50%);
$primary_d:darken($primary,50%);
$card-hover: rgba(0,0,0,0.3);

$secondary:#0080FF;
$danger-red: rgb(255, 0, 0);
$fa-phone-color:white;
$fa-page-row:#ACACAC;

$shop-select: #0C1B34;

$text-color:#333;

$row_background:#F7F7F7;
$row_background2: #f2f2f2;
$row_background3: #FFFFFF;
$brc_background: #F3F3F3;

$delivery-options:#969696;

$header_background: linear-gradient(180deg, #FFFFFF 0%, #E8E8E8 100%);
$footer_background: linear-gradient(0deg, #FFFFFF 0%, #E8E8E8 100%);

$box-shadow: 0px 2px 3px 0px rgba(12,27,52,0.5);
$modal-box-shadow: 0 2px 3px 0 rgba(183,183,183,0.5);

$brown-l:$secondary;
$brown-b:#f5f6e9;
$brown-d:$primary;

$link-color: $secondary;

$font-size-h1:40px;
$font-size-h2:30px;
$font-size-h3:30px;

$font-size-xlarge:30px;
$font-size-large:24px;
$font-medium: 20px;
$font-small:18px;
$font16:16px;
$font-smaller:14px;
$font-xsmall:12px;

$body-font:18px;

$mobile-title-size: 24px;
$mobile-body-size: 16px;

$recent-news-title-font:14px;

$header_mobile:22px;
$header_mobile_md:20px;
$header_mobile_sm:18px;


$link: #FFF;

$red: #b71c1c;
$blue: #0080FF;
$dark-blue:#004080;
$mobile-blue:#001E3C;
$green-button:#7ACF1A;
$modal-color: #434343;

$weight-light: 300;
$weight-med: 600;
// Bootstrap mapping

$input-border: $secondary !default;

// Font Awesome

$fa-font-path: "/media/fonts";
$headings-font-family: "Open Sans", sans-serif !default;

/* for editor */
$edit-color: green;


/* Image Link */
$image-link-title-font: $font-small;
$image-link-p-font: $font-smaller;

/* Filter Bar */
$filter-bar-background: #FFF;

/* Product Categories List */
$product-categories-list-background: $row_background;
$product-categories-list-background-active: $dark-blue;
$product-categories-list-background-hover:lighten($product-categories-list-background-active,40%);
$product-categories-list-font-size: 18px;

// Products
$product-shadow: $box-shadow;



// Spacer settings
$spacer-height: 120px;
$spacer-mobile-height:80px;
$home_page_spacer:170px;

$home_page_spacer_md:80px;

// Image Link
$image-link-width:370px;
$image-link-height:380px;
$image-link-image-height:220px;
$image-link-width-laptop: 314px;
$image-link-height-laptop:323px;
$image-link-image-height-laptop: 308px;

$image-link-mobile-image-height:200px;
$image-link-title-font: $font-small;
$image-link-p-font: $font-smaller;
$image-link-p-mobile:$font-xsmall;
$image-link-title-mobile: $font-smaller;

$green-button-border: 1px solid #64A915;
$green-button-hover: darken($green-button,5%);
$shop-table-selector: 2px solid #66B3FF;
