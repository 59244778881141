.image_link_list {
	margin-top: 30px;
	.image-link {
		background-color: #fff;
		box-shadow: 0 2px 3px 0 rgba(183,183,183,0.5);
		max-width: $image-link-width;
		height: $image-link-height;
		padding: 0;
		justify-content: center;
		.image-holder {
			max-height: 186px;
		}
		img {
			padding-bottom: 0 !important;
			padding-top: 0 !important;
			// max-width: $image-link-width;
			// max-height: $image-link-image-height;
			height: auto;
			display: inline-block;
		}

		@media (max-width: $screen-md-max) and (min-width: $screen-md-min){
			margin-left: 5px;
			max-height: $image-link-height-laptop;
			max-width: $image-link-width-laptop;

		}

		@media(max-width: $screen-xs-max){

			max-width: 100%;

		}

		.image-link-inner {
			padding-bottom: 21px;
			padding-left: 15px;
			padding-right: 15px;
			display: inline-block;
			background-color:white;
			bottom:0;
			left:0;
			right:0;
			position: absolute;
			h5 {
				font-size: $image-link-title-font;
				text-transform: uppercase;
				margin-bottom: 5px;
				text-align: center;
			}
			p {
				font-size: $image-link-p-font;
				line-height: 1.5;
				margin: 0 0 15px;
				text-align: center;
			}

			.button {
				font-size: 16px;
				font-weight: 600;
				// margin-bottom: 21px;
				margin-top: 0;
				min-height: 34px;
				min-width: 170px;
				padding-bottom: 6px;
				padding-top: 6px;
				text-align: center;
				text-transform: uppercase;

			}

			@media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
				h5 {
					font-size: $image-link-title-mobile;
				}

				p {
					font-size: $image-link-p-mobile;
					margin-bottom: 5px;
				}
			}
		}

		@media(max-width: $screen-xs-max){
			& {
				padding-bottom: 20px;
				max-width: 100%;

			}
			img {
				max-width: 100%;
				max-height: $image-link-mobile-image-height;
			}

			p {
				font-size: $font-xsmall;
			}

			.image-link-inner {
				display: block;
				p {
					font-size: $font-xsmall;
				}
			}

			.button {
				font-size: $font-xsmall;
				width: 100%;
			}

		}
		@media (max-width: $screen-sm-max) {
			& {
				margin-bottom: 20px;

			}

			.image-link-inner {
				h5 {
					font-size: 17px;
				}
				p {
					font-size: $font-smaller;
					margin: 0 0 10px;
				}
			}
			.button {
				font-size: $font-xsmall;
				width: 100% !important;
				text-align: center;

			}

		}

		@media (max-width: $screen-md-max) {
			& {
				margin-bottom: 20px;

			}
			img {
				max-width: 100%;
				// max-height: $image-link-mobile-image-height;
			}
		}

		@media (min-width: $screen-lg-min) {
			margin: 10px;
			margin-top: 5px;
		}
	}
}
