@import "resources/assets/sass/_variables";

@import "node_modules/font-awesome/scss/font-awesome";


@import "resources/assets/sass/bootstrap_variables";
@import "resources/assets/sass/bootstrap";

@import "resources/assets/sass/_partials/nav";
@import "resources/assets/sass/_partials/dropdown";
@import "resources/assets/sass/_partials/footer";
@import "resources/assets/sass/_partials/copyright";

@import "resources/assets/sass/_partials/home_header";

@import "resources/assets/sass/_partials/filter_bar";
@import "resources/assets/sass/_partials/posts";
@import "resources/assets/sass/_partials/post_category_links";

@import "resources/assets/sass/_partials/page";
@import "resources/assets/sass/_partials/page_row";
@import "resources/assets/sass/_partials/page_header";

@import "resources/assets/sass/_partials/property_links";

@import "resources/assets/sass/_partials/editable";
@import "resources/assets/sass/_partials/social_share";
@import "resources/assets/sass/_partials/contact_form";
@import "resources/assets/sass/_partials/image_link_list";
@import "resources/assets/sass/_partials/gallery_images";
@import "resources/assets/sass/_partials/jump_nav";
@import "resources/assets/sass/_partials/party_pack_carousel";
@import "resources/assets/sass/_partials/pack_designs";
@import "resources/assets/sass/_partials/product_list_page_categories";
@import "resources/assets/css/lightbox";
@import "resources/assets/sass/_partials/image_reel";
@import "resources/assets/sass/_partials/video";
@import "resources/assets/sass/_partials/brc-certificate";

@import "resources/assets/sass/_partials/shop";
@import "resources/assets/sass/_partials/shop/cc";
@import "resources/assets/sass/_partials/shop/product";
@import "resources/assets/sass/_partials/shop/product_added";
@import "resources/assets/sass/_partials/shop/product_listing";
@import "resources/assets/sass/_partials/shop/related_products";
@import "resources/assets/sass/_partials/shop/checkout";
@import "resources/assets/sass/_partials/shop/card_info";

body {
	-webkit-font-smoothing: antialiased;
}

.contact {
	line-height: 1.5em;
}

.anchor {
	margin-top: -50px;
	padding-bottom: 50px;
}

.grecaptcha-badge {
	visibility: hidden !important;
}

.grecaptcha-notice {
	font-size: 11px;
	line-height: 16px;
	text-align: center;

	a {
		color: #000;
	}
}

.index .spacer {
	height: $home_page_spacer;

	@media(max-width:$screen-sm-max) {
		& {
			height: $home_page_spacer_md;
		}
	}
}

.spacer {
	padding-top: $spacer-height;
	background-color: $row_background;

	&.active-sp {
		padding-top: 170px;
		transition: padding-top .5s ease-in;

		@media(max-width:$screen-xs-max) {
			padding-top: 114px;
		}

		@media(max-width:$screen-sm-max) {
			padding-top: 120px;
		}

	}

	@media(max-width:$screen-sm-max) {
		& {
			padding-top: $spacer-mobile-height;
		}
	}

	@media (min-width:$screen-lg-min) {
		& {
			padding-top: $spacer-height;
		}
	}


}

.dropdown {
	margin-top: -6px;
}

@media(max-width:$screen-xs-max) {}

@media (min-width:$screen-sm-min) {}

@media (min-width:$screen-md-min) {}

@media (min-width:$screen-lg-min) {}