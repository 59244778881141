
.modal-footer {
	.related-products-wrap {
		text-decoration: none;
	}
	.related-products {
		margin-top:15px;
		box-shadow: $modal-box-shadow;

		min-height: 240px;
		position: relative;
		display: block;


	}
	img {
		max-height: 182px;
		@media(max-width:$screen-xs-max){
			margin:auto;
			}
	}
	.related-products-inner {
		min-height: 300px;
		display: block;
		overflow:hidden;
		position: relative;
	}
	.related-button {
		text-transform: lowercase;
		font-size: $font-smaller;
		background-color:$row_background3;
		position:absolute;
		display:block;


		bottom:0;
		left:0;
		right:0;
		padding:10px;
		a {
			text-decoration: none;
		}

	}
	.product-title {
		padding:10px;
		font-size:$font-xsmall;
		text-align: center;
		text-transform: uppercase;
		background-color:$row_background;
		font-weight: normal;

	}
}

