.video {
	width:150%;
	height:100%;
	overflow:hidden;
	margin-left: -25%;
	margin-top: 20px;
	margin-bottom:15px;
	.breakout {
		position:relative;


	}
	.overlay {
		position:absolute;
			left:0;
			right:0;
			top:0;
			bottom:0;
		.text {
			position:relative;
			margin-top: 8%;
		}
		z-index:3;
	}
	.text {

		h1, h2 {
			color: #FFFFFF;
			font-weight: 600;
			text-transform: uppercase;
		}
		h1 {
			font-size: $font-size-h3;
		}
		h2 {
			font-size: $font-size-large;
		}
		img {
			max-width:100px;
			max-height: 100px;
		}
		@media(max-width:$screen-sm-max){
			img {
				max-width:50px;
				max-height: 50px;
			}
			h1 {
				font-size: $font-size-large;
			}
			h2 {
				font-size: $font-small;
			}
		}




	}
	.video_image {
		position: relative;
		width:100%;
		z-index:0;
	}
	.cover {
		position:absolute;
		z-index:1;
		background-color: rgba(0,0,0,0.7);
		width:100%;
		height:100%;
		display: none;
	}
	&.active {
		.cover, iframe {
			display:block;
		}
		.overlay {
			display: none;
		}
	}

	iframe {
		position:absolute;
		left:25%;
		z-index: 2;
		height: 100%;
		display: none;
		max-width: 50%;

	}
	@media(max-width:$screen-xs-max){
		& {
			margin-left:0;
			width:100%;
		}
	}

}
