.gallery_images {
	.col-sm-3 {
		margin-bottom:15px;

		a {
			display: block;
    		height: 200px;
    		overflow: hidden;
    		position: relative;

			img {
				position: absolute;
			    top: -100%;
			    left: 0;
			    right: 0;
			    bottom: -100%;
			    margin: auto;
			}
		}
	}
}
