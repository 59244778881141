.image_reel {
	width:150%;
	overflow:hidden;
	margin-left: -25%;
	margin-top:30px;

	.breakout {

		position:relative;

		img {
			position:relative;
			max-width:none;
		}
	}
	@media(max-width:$screen-xs-max){
		& {
			margin-left:0;
			width:100%;
		}
	}
}
