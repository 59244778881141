.cc {
	float: right;
	margin-top: -28px;
	margin-right: 43px;


	span {
		width: 36.5px;
		height: 22px;
		position: absolute;
		z-index: 200;
		background-size: 100%;
		top:11px;
		right: 18px;
	}

	.amex {
		background-image:url('/build/media/img/components/creditcards/amex.png');

		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
			background-image:url('/build/media/img/components/creditcards/amex@2x.png');
		}
	}

	.credit {
		background-image:url('/build/media/img/components/creditcards/credit.png');

		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
			background-image:url('/build/media/img/components/creditcards/credit@2x.png');
		}
	}

	.dankort {
		background-image:url('/build/media/img/components/creditcards/dankort.png');

		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
			background-image:url('/build/media/img/components/creditcards/dankort@2x.png');
		}
	}

	.dinersclub {
		background-image:url('/build/media/img/components/creditcards/diners.png');

		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
			background-image:url('/build/media/img/components/creditcards/diners@2x.png');
		}
	}

	.discover {
		background-image:url('/build/media/img/components/creditcards/discover.png');

		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
			background-image:url('/build/media/img/components/creditcards/discover@2x.png');
		}
	}

	.forbru {
		background-image:url('/build/media/img/components/creditcards/forbru.png');

		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
			background-image:url('/build/media/img/components/creditcards/forbru@2x.png');
		}
	}

	.google {
		background-image:url('/build/media/img/components/creditcards/google.png');

		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
			background-image:url('/build/media/img/components/creditcards/google@2x.png');
		}
	}

	.jcb {
		background-image:url('/build/media/img/components/creditcards/jcb.png');

		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
			background-image:url('/build/media/img/components/creditcards/jcb@2x.png');
		}
	}

	.laser {
		background-image:url('/build/media/img/components/creditcards/laser.png');

		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
			background-image:url('/build/media/img/components/creditcards/laser@2x.png');
		}
	}

	.maestro {
		background-image:url('/build/media/img/components/creditcards/maestro.png');

		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
			background-image:url('/build/media/img/components/creditcards/maestro@2x.png');
		}
	}

	.mastercard {
		background-image:url('/build/media/img/components/creditcards/mastercard.png');

		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
			background-image:url('/build/media/img/components/creditcards/mastercard@2x.png');
		}
	}

	.money {
		background-image:url('/build/media/img/components/creditcards/money.png');

		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
			background-image:url('/build/media/img/components/creditcards/money@2x.png');
		}
	}

	.paypa {
		background-image:url('/build/media/img/components/creditcards/paypa.png');

		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
			background-image:url('/build/media/img/components/creditcards/paypa@2x.png');
		}
	}

	.shopify {
		background-image:url('/build/media/img/components/creditcards/shopify.png');

		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
			background-image:url('/build/media/img/components/creditcards/shopify@2x.png');
		}
	}

	.solo {
		background-image:url('/build/media/img/components/creditcards/solo.png');

		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
			background-image:url('/build/media/img/components/creditcards/solo@2x.png');
		}
	}

	.visa {
		background-image:url('/build/media/img/components/creditcards/visa.png');

		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
			background-image:url('/build/media/img/components/creditcards/visa@2x.png');
		}
	}

}


