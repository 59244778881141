.details_form {
	#address_line_1, #address_line_2, #city {
		height: 42px;
	}
	color:$modal-color;
	font-size:$font16;
	h2 {
		font-size: $font-size-large;
		.fa-lock {
			color:$primary;
			font-size: $font-size-h2;
		}
	}
	h3 {
		font-size: $font-medium;
		font-weight: $weight-med;
	}
	.control-label {
		text-align:left;
	}
	.form-control {
		font-size: 18px;
	}
	.expiry_seperator {
		font-size: 24px;
	}
	.payment-errors {
		margin-top: 1em;
	}

	.btn {
		font-size: $font16;
		font-weight: $weight-med;
		text-transform: uppercase;
		margin-top:20px;
		min-width: 170px;
	}
	p {
		font-size: $font16;
		font-weight: $weight-light;
	}
	img {
		margin-top:28px;

	}
	.ship, .terms {
		margin-left:0;
	}

	.fa-chevron-circle-up {
		font-size: $font-size-h2;
		color:$fa-page-row;
		margin-top: 36px;
		margin-bottom:40px;

	}
}

@media(max-width:$screen-sm-max){
	.details_form .control-label, .form-control {
		font-size:$font-smaller;

	}
}
